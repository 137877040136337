<template>
  <span>
    <el-button size="mini" type="warning" @click="start">{{$t('role.setResource')}}</el-button>
    <el-dialog
      :title="$t('role.setResource')"
      v-if="showDialog"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      width="600px">
      <el-tree
        v-loading="loading"
        ref="tree"
        :data="tree"
        show-checkbox
        :check-strictly="true"
        highlight-current
        :indent="24"
        node-key="id">
        <div class="custom-tree-item" slot-scope="{data}">
          <div class="tree-item-label">{{data.name}}</div>
          <el-tag size="mini">{{$t('resource.' + typeMap[data.type])}}</el-tag>
        </div>
      </el-tree>
      <div slot="footer">
        <show-space>
          <el-button size="medium" type="primary" :disabled="loading" @click="handleConfirm">{{$t('button.confirm')}}</el-button>
          <el-button size="medium" @click="showDialog = false" :disabled="loading">{{$t('button.cancel')}}</el-button>
        </show-space>
      </div>
    </el-dialog>
  </span>
</template>
<script>
import {getResourceIds, saveResources} from "@/api/role";
import {list as getList} from "@/api/resource";
import {listToTree, showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveRoleResourceButton",
  
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  
  data() {
    return {
      showDialog: false,
      loading: false,
      tree: [],
      typeMap: {
        'dir': 'dir',
        'menu': 'menu',
        'btn': 'btn',
        'func': 'func',
        'link': 'link',
      }
    }
  },
  
  methods: {
    start() {
      this.showDialog = true
      this.loading = true
      getList({pageNo: 1, pageSize: 1000000}).then(res => {
        let list = res.data.list
        this.tree = listToTree(list)
        
        getResourceIds({id: this.id}).then(res => {
          console.log(res.data)
          this.$refs.tree.setCheckedKeys(res.data);
        })
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
  
    handleConfirm() {
      let ids = this.$refs.tree.getCheckedKeys();
      this.loading = true
      saveResources({
        id: this.id,
        resourceIds: ids
      }).then(() => {
        showSuccessMsg(this.$t('message.title'), this.$t('message.saveSuccess'))
        this.showDialog = false
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.custom-tree-item {
  flex: 1;
  display: flex;
  align-items: center;
  
  .tree-item-label {
    flex: 1;
    margin-right: 8px;
    text-align: left;
  }
  
  .tree-item-mark {
    font-size: 12px;
    color: #666666;
  }
}
</style>