<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="role"
    :get-list-func="getList"
    :queryParam="queryParam"
    :show-import-excel-button="false"
    :show-export-excel-button="false"
    can-select>
    <div slot="searchBox">
      <role-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <show-space>
        <save-role-button @xzdListPageReload="xzdListPageReload"/>
        <delete-role-button :ids="selectedRows.map(v => v.id)" @xzdListPageReload="xzdListPageReload"/>
      </show-space>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="name" :label="$t('role.name')"/>
      <el-table-column prop="description" :label="$t('role.description')"/>
      <el-table-column prop="sort" width="100" :label="$t('role.sort')"/>
      <el-table-column :label="$t('common.operate')" width="300" align="center">
        <template slot-scope="scope">
          <show-space>
            <save-role-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
            <save-role-resource-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
            <delete-role-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
          </show-space>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/role";
import RoleListSearchBox from "./RoleListSearchBox";
import SaveRoleButton from "../buttons/SaveRoleButton";
import DeleteRoleButton from "../buttons/DeleteRoleButton";
import SaveRoleResourceButton from "@/views/system/role/buttons/SaveRoleResourceButton";

export default {
  name: "RoleList",
  components: {SaveRoleResourceButton, DeleteRoleButton, SaveRoleButton, RoleListSearchBox},
  data() {
    return {
      queryParam: {},
      getList,
    }
  },
  
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}
</script>
<style scoped>
</style>